import { Col, Row } from 'antd';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { isEmpty, find, intersection, isEqual } from 'lodash';
import {
  getWorkPlanTaskConfigurationsByIds,
  getWorkPlanTasksByIds
} from 'redux/selectors/workPlanTasks';
import { useSelector } from 'react-redux';
import ClientInteractionsSettingsDrawer from './ClientInteractionsSettingsDrawer/ClientInteractionsSettingsDrawer';
import ClientInteractionsTable from './components/ClientInteractionsTable';
import TableFilters from './components/TableFilters';
import { getUnitsByIds } from '../../redux/selectors/units';
import { getUsersByIds } from '../../redux/selectors/users';
import { getChecklistsDefinitionsByIds } from '../../redux/selectors/checklists';
import { hasConflictFilters } from '../DashboardPage/utils';

const ClientInteractions = () => {
  const { t } = useTranslation();
  // Пагинация для ленивой загрузки кастомных полей, используется в компонентах ниже
  // src/pages/ClientInteractionsPage/components/CustomFieldsFilters.jsx
  // src/pages/ClientInteractionsPage/ClientInteractionsSettingsDrawer/Components/CustomFieldsColumns.jsx
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const workPlanTaskConfigurationsByIds = useSelector(
    state => getWorkPlanTaskConfigurationsByIds(state),
    isEqual
  );

  const workPlanTasksByIds = useSelector(state => getWorkPlanTasksByIds(state), isEqual);

  const taskAssignmentsIdsFromFilters = useSelector(
    state => state.clientInteractionsList.filters?.taskAssignmentsIds,
    isEqual
  );

  const units = useSelector(state => getUnitsByIds(state));
  const users = useSelector(state => getUsersByIds(state));
  const checklists = useSelector(state => getChecklistsDefinitionsByIds(state));
  const clientInteractionsFilters = useSelector(
    state => state.clientInteractionsList.filters,
    isEqual
  );

  const arrayOfUsers = Object.keys(users);
  const arrayOfUnits = Object.keys(units);
  const arrayOfChecklists = Object.keys(checklists);

  const hasConflicts =
    hasConflictFilters({
      availableReduxFilters: arrayOfUsers,
      currentFilters: clientInteractionsFilters?.operatorsIds || []
    }) ||
    hasConflictFilters({
      availableReduxFilters: arrayOfUsers,
      currentFilters: clientInteractionsFilters?.reviewersIds || []
    }) ||
    hasConflictFilters({
      availableReduxFilters: arrayOfUnits,
      currentFilters: clientInteractionsFilters?.unitsIds || []
    }) ||
    hasConflictFilters({
      availableReduxFilters: arrayOfChecklists,
      currentFilters: clientInteractionsFilters?.checklistDefinitionsIds || []
    });

  const selectedWorkPlanTaskConfigurationId = isEmpty(taskAssignmentsIdsFromFilters)
    ? undefined
    : find(workPlanTaskConfigurationsByIds, configuration => {
        const lastTask = workPlanTasksByIds[configuration?.lastTaskId];
        return !isEmpty(intersection(taskAssignmentsIdsFromFilters, lastTask?.assignmentsIds));
      })?.id;

  return (
    <Row gutter={[16, 0]}>
      <Helmet>
        <title>{t('pagesMeta.clientInteractionsPage.title')}</title>
      </Helmet>
      <Col span={5}>
        <TableFilters
          selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
          hasConflicts={hasConflicts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
        />
      </Col>
      <Col span={19}>
        <ClientInteractionsTable
          selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
          hasConflicts={hasConflicts}
        />
      </Col>
      <ClientInteractionsSettingsDrawer
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
      />
      <ClientInteractionDrawer padding="0" />
    </Row>
  );
};

export default ClientInteractions;
