import React, { useState } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Col, Row, Tooltip } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import EmptyWidget from '../../Components/EmptyWidget';
import WidgetTable from '../../../../components/Dashboard/WidgetTable';
import { LargeWidgetCard } from '../../styled';
import SCol from '../../../../components/Standard/SCol';
import SRow from '../../../../components/Standard/SRow';
import { widgetInfoTextSetter, widgetNameSetter } from '../../utils';
import { WidgetTitle } from '../../../../components/WidgetTitle';
import SettingsModal from '../../Components/SettingsModal';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';
import { getChecklistItems, prepareColumns, prepareRows } from './prepareTableData';

const TableUserFieldAnalytics = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;
  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.PERCENTAGE.value);
  const [initialState, setInitialState] = useState({});
  const [sortOrder, setSortOrder] = useState(null);
  const isEmptyWidgetData = isEmpty(widgetData);
  const customFields = useSelector(state => state.userCustomFieldsResource.byIds);
  const widgetsAnalytics = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);

  const widgetValue = get(widgetsAnalytics, 'data', []);
  const widgetMeta = get(widgetsAnalytics, 'meta', []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  const checklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );
  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});

  const checklistItems = getChecklistItems(customFields, widgetValue);

  const tableColumns = prepareColumns({
    checklistItems,
    filters,
    sortOrder
  });

  const tableRows = prepareRows({
    widgetValue: Object.values(widgetValue)
  });

  const getSortString = ({ sorter }) => {
    if (!sorter.order) return '';

    const getSorterKey = () => {
      return sorter.columnKey;
    };

    return sorter.order === 'ascend' ? getSorterKey() : `-${getSorterKey()}`;
  };

  const onChange = async ({ pagination, sorter }) => {
    setInitialState({
      number: pagination?.current,
      size: pagination?.pageSize
    });

    const columnsKeys = tableColumns.map(item => item.key);
    const childrenKeys = tableColumns.map(column => {
      return column?.children && column?.children.map(item => item?.key);
    });
    const { columnKey } = sorter;
    setSortOrder(sorter?.order);

    const isNewSort = columnsKeys.includes(columnKey) || childrenKeys.includes(columnKey);

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        sort: columnKey ? getSortString({ sorter, viewMode: filters?.viewMode }) : ''
      })
    );
  };

  return (
    <>
      {isEmptyWidgetData ||
        (isWarningWidget && (
          <EmptyWidget
            key={id}
            widgetData={widgetData}
            loadingWidgetId={loadingWidgetId}
            isWarningWidget={isWarningWidget}
          />
        ))}
      {!isEmptyWidgetData && !isWarningWidget && (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>
              </SCol>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate widgetData={widgetData} dateLastUpdate={dateLastUpdate} />
              </SCol>
            </Row>
            <WidgetTable
              columns={tableColumns}
              dataSource={tableRows}
              loading={loadingWidgetId === id}
              size="small"
              bordered
              showSorterTooltip={false}
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
              pagination={false}
              onChange={(pagination, filters, sorter) => onChange({ pagination, sorter })}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableUserFieldAnalytics;
