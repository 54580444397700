import { get, post, put } from 'redux-bees';

const aiSettingsEndpoints = apiPath => ({
  getAiSettings: { method: get, path: `${apiPath}/ai_tasks/list` },
  createAiSetting: { method: post, path: `${apiPath}/ai_tasks/add` },
  updateAiSetting: { method: put, path: `${apiPath}/ai_tasks/update` },
  deleteAiSetting: { method: post, path: `${apiPath}/ai_tasks/delete/` },
  reanimateAiSettings: { method: post, path: `${apiPath}/ai_tasks/reanimate/` },
  validateAiSettings: { method: post, path: `${apiPath}/ai_tasks/validate/` },
  startAiSettings: { method: post, path: `${apiPath}/ai_tasks/start/` },
  stopAiSettings: { method: post, path: `${apiPath}/ai_tasks/stop/` },
  getDataSet: { method: get, path: `${apiPath}/ai_dataset/list` },
  getCommunicationByStatus: { method: get, path: `${apiPath}/ai_tasks/communications_by_status` }
});

export default aiSettingsEndpoints;
