import { Col, Modal, Row, Skeleton } from 'antd';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import _, { isEmpty, isEqual, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import uniqid from 'uniqid';
import CustomFieldEditor from './CustomFieldEditor';

const CustomFieldsManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const integrations = useSelector(
    state => Object.values(state.integrationsResource.byIds),
    isEqual
  );
  const loading = useSelector(state => state.customFieldsResource.loading);
  const customFields = useSelector(
    state => orderBy(Object.values(state.customFieldsResource.byIds), 'created_at'),
    (current, prev) => isEqual(current, prev) && current?.length === prev?.length
  );
  const [editingCustomField, setEditingCustomField] = useState(null);
  const [addingCustomField, setAddingCustomField] = useState(null);

  useEffect(() => {
    dispatch(
      customFieldsResource.operations.load({ organization_id: organizationId, pagination: false })
    );
  }, []);

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  const onDeleteCustomField = customField => {
    if (customField.id === addingCustomField?.id) {
      return setAddingCustomField(null);
    }
    Modal.confirm({
      title: `${t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.title'
      )} ${customField.name}?`,
      okText: t(
        'integrationsSettingsPage.integrationPage.customFieldsSettings.deleteConfirm.okText'
      ),
      okType: 'danger',
      onOk: () => {
        dispatch(customFieldsResource.operations.deleteById({ id: customField.id }));
        setAddingCustomField(null);
        setEditingCustomField(null);
      }
    });
  };

  const onSubmitCustomField = async customField => {
    if (customField.id === addingCustomField?.id) {
      // create custom field from state

      const resource = await dispatch(customFieldsResource.operations.create(customField));
      if (resource) {
        setAddingCustomField(null);
      }

      return;
    }

    if (customField.id === editingCustomField?.id) {
      // update custom field props

      const result = await dispatch(customFieldsResource.operations.updateById(customField));
      if (result) {
        setEditingCustomField(null);
      }

      return;
    }

    setEditingCustomField(customField);
  };

  const onAddCustomField = () => {
    setAddingCustomField({
      id: uniqid(),
      fieldType: null,
      key: '',
      name: '',
      usedForFilters: true,
      usedForReviews: true,
      usedForAI: true
    });
  };

  return (
    <SCard bordered shadowed>
      <Row>
        <Col span={24}>
          <Row gutter={[0, 32]} style={{ marginBottom: '-16px' }}>
            {customFields.map(customField => (
              <CustomFieldEditor
                key={customField.id}
                customField={customField}
                addingId={addingCustomField?.id}
                editingId={editingCustomField?.id}
                onDelete={onDeleteCustomField}
                onSubmit={onSubmitCustomField}
                integration={_.keyBy(integrations, 'id')[customField?.originIntegrationId]?.name}
              />
            ))}

            {!isEmpty(addingCustomField) && (
              <CustomFieldEditor
                customField={addingCustomField}
                addingId={addingCustomField?.id}
                editingId={editingCustomField?.id}
                onDelete={onDeleteCustomField}
                onSubmit={onSubmitCustomField}
                integration={
                  _.keyBy(integrations, 'id')[addingCustomField?.originIntegrationId]?.name
                }
              />
            )}

            <Col span={24}>
              <Row justify="end">
                <Col>
                  {isEmpty(addingCustomField) && isEmpty(editingCustomField) && (
                    <SButton onClick={onAddCustomField} type="primary" size="big" width="230px">
                      {t('integrationsSettingsPage.integrationPage.customFieldsSettings.addField')}
                    </SButton>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

export default CustomFieldsManager;
