export default {
  constants: {
    accessLevels: {
      noInvitationSent: 'Invite not sent',
      invitationSent: 'Invite sent',
      invitationAccepted: 'Logged in',
      userBlocked: 'No access'
    },
    plans: {
      free: 'Free',
      start: 'Start',
      profi: 'Profi',
      notInstalled: 'Not installed'
    },
    plansDescription: {
      checklistConstructor: 'Score card constructor',
      omnichannelReviewsCount100:
        '100 omni-channel evaluations (calls, chats, email, tickets) per month',
      omnichannelReviews: 'Omni-channel evaluations (calls, chats, email, tickets)',
      dashboardsCount2: '2 dashboards',
      unlimitedDashboards: 'Unlimited number of dashboards',
      communicationPanel: 'Communication panel',
      library: 'Library',
      emailNotification: 'Email notifications',
      appNotifications: 'App notifications ',
      dashboardsShare: 'Dashboard sharing',
      communicationsTableShare: 'Communication panel sharing',
      calibrations: 'Calibration sessions',
      unlimitedTags: 'Unlimited number of automatic tags',
      unlimitedIntegrations: 'Unlimited number of integrations',
      integrationsCount1: '1 CRM or VoIP integration'
    },
    integrations: {
      customHttpTelephony: {
        title: 'API-telephony',
        description: 'Integration with software PBXs'
      },
      customHttpText: {
        title: 'API-text',
        description: 'Integration with text channels'
      },
      bitrixCrm: {
        title: 'Bitrix24',
        description: 'Bitrix 24 CRM integration'
      },
      amoCrm: {
        title: 'AmoCRM',
        description: 'AmoCRM integration'
      },
      sipuni: {
        title: 'Sipuni',
        description: 'Sipuni integration'
      },
      uis: { title: 'UIS/Comagic', description: 'Integration with UIS/Comagic' },
      naumen: {
        title: 'Naumen',
        description: 'Naumen integration'
      },
      mango: {
        title: 'Mango',
        description: 'Mango integration'
      }
    },
    integrationsSyncPeroids: {
      month: 'Month',
      threeMonth: '3 months',
      sixMonth: '6 months',
      allTime: 'All time'
    },
    taskDefinitionTypes: {
      automatic: 'Automatic',
      manual: 'Manual'
    },
    userStatuses: {
      active: 'Active',
      disabled: 'Disabled'
    },
    permissionsDescriptions: {
      canPerformClientInteraction: {
        title: 'The employee communicates with clients (calls, chats, tickets, email).',
        description: ''
      },
      canSignIn: {
        title: 'Employee has access to Qolio',
        description: ''
      },
      canSeeAndManageHiddenComments: {
        title: 'Add and see hidden comments',
        description: ''
      },
      canAccessUserAnalyticsPageChartsTab: {
        title: 'Access to profile',
        description: 'Employee has access to personal analytics page'
      },
      canAccessUserAnalyticsPageReviewsTab: {
        title: 'Access to a personal rating list in a profile',
        description: 'The employee sees the list of evaluated communications and comments to them'
      },
      canAccessUserAnalyticsPageCommentsTab: {
        title: '',
        description: ''
      },
      canReplyCommnets: {
        title: 'Response to comments on his/her communications',
        description: 'Employee may respond to comments on his/her communications evaluations'
      },
      canReplyAnyoneComments: {
        title: 'Reply to comments on all communications',
        description: 'An employee can respond to comments on grades for all communications'
      },
      canAccessLibrary: {
        title: 'Access to the library',
        description: ''
      },
      canAddLibraryItems: {
        title: 'Adding files to the library',
        description: ''
      },
      canSeeUnitClientInteractions: {
        title: 'View all communications in the department',
        description: ''
      },
      canDownloadClientInteraction: {
        title: 'Download communication file',
        description: ''
      },
      canMakeReview: {
        title: 'The right to evaluate and comment on communications in your department',
        description: ''
      },
      canSeeUnitReviews: {
        title: 'View other user/s evaluations and comments',
        description: ''
      },
      canSeeAndUpdateAssignedTasks: {
        title: 'Managing your tasks',
        description: ''
      },
      canManageTasks: {
        title: 'Adding and managing the created tasks',
        description: ''
      },
      canManageUnitTasks: {
        title: 'Adding and managing department tasks',
        description: ''
      },
      canManageLibrary: {
        title: 'Library management',
        description: 'Ability to add/remove/edit folders and files inside the library'
      },
      canManageChecklistDefinitions: {
        title: 'Score cards management',
        description: ''
      },
      canManageTaskDefinitions: {
        title: 'Tag management',
        description: ''
      },
      canManageOrganizationStructure: {
        title: 'Department and user management',
        description: ''
      },
      canManageOrganizationSettings: {
        title: 'Organisation general settings management',
        description: ''
      },
      canManageIntegrations: {
        title: 'Integrations management',
        description: ''
      },
      canSeeDashboards: {
        title: 'Access to analytics',
        description: ''
      },
      canSeeUnitOperatorsAnalyticsPage: {
        title: 'Access to profiles of the employees who communicate with clients',
        description: ''
      },
      canAccessTeamPage: {
        title: 'Access to the "Team" page',
        description: ''
      },
      canManageCalibrationSessions: {
        title: 'Access to calibration sessions',
        description: ''
      },
      canSeeReviewerName: {
        title: 'Can see reviewer name',
        description: ''
      },
      canManageWorkPlanTaskConfigurations: {
        title: 'Load balancing control',
        description: ''
      },
      canCreateAppellations: {
        title: 'Can create appellations',
        description: ''
      },
      canAdministrateAppellations: {
        title: 'Can administrate appellations',
        description: ''
      },
      canWatchAppellations: {
        title: 'Can watch appellations',
        description: ''
      },
      canSeeUnitAppellations: {
        title: 'Access to the appeal tracker',
        description: ''
      },
      canAccessUserAnalyticsPageOperatorsAppellationsTab: {
        title: 'Access to appeals through a personal account (for operators)',
        description: ''
      },
      canAccessAppellationsAnalyticsPage: {
        title: 'Access to analytics on appeals',
        description: ''
      },
      canUpdateClientInteractionOperator: {
        title: 'El derecho a cambiar el operador responsable de la llamada',
        description: ''
      },
      canAiTranscribeClientInteractions: {
        title: 'The employee has access to the transcription functionality',
        description: ''
      },
      canAiAnalyzeClientInteractions: {
        title: 'The employee has access to the AI sections',
        description: ''
      },
      canEditReview: {
        title: 'Has the right to edit any ratings',
        description: ''
      }
    },
    widgetsDescriptions: {
      checklistDefinitionAverageScore: {
        title: 'Score card average score',
        description:
          'This widget shows the total average score on a particular score card  used to check calls.'
      },
      phoneCallsCount: {
        title: 'Calls amount',
        description: 'This widget calculates the number of calls.'
      },
      phoneCallsAverageDuration: {
        title: 'Average call duration',
        description:
          'This widget shows the average duration of all the selected calls. The display format is min:sec (minutes : seconds).'
      },
      totalPhoneCallsDuration: {
        title: 'Total call duration',
        description:
          'This widget shows the total duration of all your selected conversations.  The format of display is hr:min:sec (hours : minutes : seconds).'
      },
      reviewedClientInteractionsDuration: {
        title: 'Total duration of evaluated calls',
        description:
          'This widget displays the total duration of all evaluated calls. Calculation is based on the date of assessment.'
      },
      reviewedClientInteractionsPercentage: {
        title: 'Evaluation percentage',
        description:
          'This widget shows what percentage of calls was evaluated out of the total number of calls made during the period you selected.'
      },
      checklistDefinitionAverageScoreByUnits: {
        title: 'Comparison of average score between the departments',
        description:
          'This  bar graph widget shows comparison of the average score of the score card between the departments.'
      },
      checklistDefinitionAverageScoreByOperators: {
        title: 'Comparison of average score between employees',
        description:
          'This  bar graph widget shows a comparison of the average score on a score card between selected employees.'
      },
      checklistDefinitionAverageScoreByChecklistDefinitions: {
        title: 'Comparison of average score between score cards',
        description:
          'This bar graph widget  shows a comparison of the total average score between different score cards.'
      },
      phoneCallsCountByUnits: {
        title: 'Comparison of the number of calls between the departments',
        description:
          'This bar graph widget compares the number of calls in different departments or teams.'
      },
      phoneCallsCountByOperators: {
        title: 'Comparison of the number of calls between employees',
        description:
          'This bar graph widget compares the number of calls of different employees (in one department or for the company as a whole).'
      },
      reviewsCountByReviewers: {
        title: 'Comparison of supervisor evaluations',
        description:
          'This bar graph widget shows how many evaluations each supervisor has made for the selected period.'
      },
      ratingFlagsCount: {
        title: 'The number of flags and comments',
        description:
          'This pie chart widget shows in relation how many comments with and without flags have been created in a selected time in the company as a whole or in your selected department / team, or even one specific employee.'
      },
      ratingFlagsCountByUnits: {
        title: 'Comparison of flags by departments',
        description:
          'TThis bar graph widget shows in relation  how many comments with and without flags were created in the departments/commands for a selected time.'
      },
      ratingFlagsCountByOperators: {
        title: 'Comparison of the number of flags by employees',
        description:
          'This widget in the form of a bar graph shows in relation  how many comments with and without flags were created for each employee for a selected time.'
      },
      labelsCountByOperators: {
        title: 'Comparison of tags by employees',
        description:
          'This bar graph widget shows in relation  how many tags were assigned to each employee/s calls during the selected time.'
      },
      labelsCountByUnits: {
        title: 'Comparison of the number of tags by departments',
        description:
          'This bar graph widget shows in relation  how many tags were assigned to each department/team calls during the selected time.'
      },
      checklistDefinitionQuestionsAverageScores: {
        title: 'Comparison of average score on score card questions',
        description:
          'This  bar graph widget shows a comparison, what is the average score for each question (evaluation point) on the score card.'
      },
      checklistDefinitionQuestionsAverageScoresByQuestionGroups: {
        title: 'Comparison of the average score of groups of questions',
        description:
          ' This  bar graph widget shows a comparison, what is the average score in each question (evaluation point) of each group on the score card and comparison of groups with each other.'
      },
      checklistDefinitionQuestionGroupsAverageScores: {
        title: 'Comparison of average score in a score card groups',
        description:
          'This bar graph widget  shows a comparison of the average score  in each group of questions (evaluation points)  of the score card.'
      },
      phoneCallsAverageDurationByUnits: {
        title: 'Comparison of average call duration by departments',
        description:
          'This  bar graph widget compares the average duration of all calls in each department. The display format is min:ecs (minutes : seconds).'
      },
      phoneCallsAverageDurationByOperators: {
        title: 'Comparison of average duration of calls by employees',
        description:
          'This  bar graph widget compares the average duration of all calls of each employee. The display format is min:ecs (minutes : seconds).'
      },
      reviewsCountByReviewersHistory: {
        title: 'The amount of evaluated calls dynamics',
        description:
          'This diagram widget shows how many evaluations did each supervisor make in a certain period of time.'
      },
      checklistDefinitionAverageScoreByOperatorsHistory: {
        title: 'Average score dynamics by employees',
        description:
          'This diagram widget shows the change in the average score of one score card of different employees in each period of time.'
      },
      checklistDefinitionAverageScoreByUnitsHistory: {
        title: 'Average score dynamics by departments',
        description:
          'This diagram widget shows the change in the average score of one score card in different departments and teams in each period of time.'
      },
      averageScoreByQuestionsHistory: {
        title: 'Score dynamics on the score card questions',
        description:
          'This diagram  widget shows the change in the average score for each question (evaluation point) in the score card for each period of time.'
      },
      averageScoreByQuestionGroupsHistory: {
        title: 'Score dynamics by score card question groups',
        description:
          'This diagram widget shows the change in the average score for groups of questions (evaluation points) in the score card in each period of time.'
      },
      phoneCallsAverageDurationByOperatorsHistory: {
        title: 'Dynamics of average duration of a call by employees',
        description:
          'This graph widget shows the change in the average duration of calls of each employee at certain intervals of time. The format of the display of the scale Y - min:sec (minutes : seconds).'
      },
      phoneCallsAverageDurationByUnitsHistory: {
        title: 'Dynamics of average duration of a call by departments',
        description:
          'This graph widget  shows the change in the average duration of calls in each department or team at certain intervals of time. The format of the display of the scale Y - min:sec (minutes : seconds).'
      },
      clientInteractionsCountByOperatorsHistory: {
        title: 'Dynamics of calls by employees',
        description:
          'This diagram widget shows the change in the number of calls of each employee in each period of time'
      },
      clientInteractionsCountByUnitsHistory: {
        title: 'Dynamics of number of calls by departments',
        description:
          'This diagram widget shows the change in the number of calls in different departments or teams in each period of time.'
      },
      tableReviewsCountByOperators: {
        title: 'Employees evaluations results',
        description:
          'The widget displays the average score for an employee on a particular date, the total score for the month, and the number of evaluations.'
      },
      tableReviewsCountByOperatorsWithKpi: {
        title: 'KPI by operators',
        description:
          'Shows the average score by agent over the last five weeks, plus an additional table based on KPI settings'
      },
      tableChecklistItemsByOperators: {
        title: 'Employees results by criterions',
        description:
          "The widget displays the average score for questions or groups of a score card  in the operators' section."
      },
      tableChecklistItemsHistory: {
        title: 'Evaluations results by criterions',
        description:
          'The widget displays the average score for a question or group of questions on a particular date, the total score for the month and the number of evaluations.'
      },
      flagsAndComments: {
        title: 'Flags and comments',
        description:
          'The widget displays the number of flags and comments by operators, by departments and by company'
      },
      tableChecklistsScoresByOperators: {
        title: 'Results of evaluations by score cards',
        description:
          'The widget displays the results of evaluations of employees or units on the selected score cards for a set period'
      },
      tableReviewsCountByReviewers: {
        title: 'Number of checks by auditors',
        description:
          'The widget displays the number of checks by reviewers for the selected assessment forms for a specified period'
      },
      tableReviewsWithScores: {
        title: 'Employee results in the form of assessment',
        description:
          'The widget displays checks by employee, along with the results of checks by criteria, and groups of criteria in the form of an assessment'
      },
      tableUserFieldAnalytics: {
        title: 'User Field Analytics',
        description: 'The widget allows visualizing the statistics of the number of all custom field values across different operators'
      }
    },
    reviewCallStatuses: {
      all: 'All',
      checked: 'Evaluated',
      unchecked: 'Not evaluated'
    },
    callsDirections: {
      all: 'All',
      incoming: 'Incoming',
      outcoming: 'Outcoming'
    },
    weekendViewStatuses: {
      view: 'Show',
      hide: 'Do not show'
    },
    communicationDirections: {
      incoming: 'Incoming',
      outcoming: 'Outcoming'
    },
    sessionsDirections: {
      pending: 'Pending',
      done: 'Done'
    },
    reviewStatuses: {
      all: 'All',
      reviewed: 'Evaluated',
      notReviewed: 'Not evaluated'
    },
    locales: {
      ru: 'Russian',
      en: 'English',
      es: 'Spanish',
      it: 'Italian'
    },
    clientInteractionDirections: {
      all: 'All',
      incoming: 'Incoming',
      outcoming: 'Outcoming',
      unknown: 'Unknown'
    },
    ratingMethods: {
      simple: 'Simple score',
      weighted: 'Weighted score'
    },
    ratingCalculations: {
      sum: 'Sum',
      average: 'Average'
    },
    ratingModes: {
      numbers: 'Numeric',
      percentage: 'Percentage'
    },
    autofailEnabled: {
      true: 'Yes',
      false: 'No'
    },
    clientInteractionTypes: {
      chat: 'Chats',
      ticket: 'Tickets',
      phone_call: 'Calls',
      email: 'E-mail',
      other: 'Other'
    },
    flagsColors: {
      red: 'Red flag',
      green: 'Green flag',
      yellow: 'Yellow flag',
      withoutFlag: 'No flag'
    },
    columnTypes: {
      communicationType: { title: 'Type' },
      clientPhoneNumber: { title: 'Phone number' },
      startedAt: {
        title: 'Communication date and time',
        columnName: 'Date and time'
      },
      nps: { title: 'NPS' },
      direction: { title: 'Direction' },
      text_conversation_response_time_in_seconds: { title: 'Response time' },
      email: { title: 'E-mail' },
      clientId: { title: 'Client ID' },
      mangoSalt: { title: 'Key' },
      mangoApiKey: { title: 'Product Code' },
      chatId: { title: 'Chat number' },
      ticketId: { title: 'Ticket number' },
      partsCount: {
        title: 'Number of messages',
        columnName: 'Number of msgs.'
      },
      duration: {
        title: 'Duration',
        columnName: 'Dur.'
      },
      text_conversation_ended_at: { title: 'Date and time of closing' },
      unitId: { title: 'Department' },
      operatorId: { title: 'Full name' },
      isReviewed: { title: 'Status' },
      score: {
        title: 'Evaluation result',
        columnName: 'Evaluation'
      },
      checklistDefinitionName: { title: 'Score card' },
      reviewReviewerId: { title: 'The full name of the evaluator' },
      reviewCreatedAt: { title: 'Evaluation date' },
      reviewComments: { title: 'Flags and comments' },
      taskTaskDefinitions: { title: 'Tags' },
      reviewTasksCount: { title: 'Tasks' },
      taskPriority: { title: 'Task priority' },
      taskStatus: { title: 'Task status' },
      taskAssigneeId: { title: 'Task responsible for' },
      status: { title: 'Contact status' },
      crmEntityId: { title: 'Deal/Ticket' },
      createdAt: {
        title: 'Date of creation'
      },
      updatedAt: {
        title: 'Date of update'
      }
    },
    boolValues: {
      true: 'Yes',
      false: 'No'
    },
    colorPercantageZone: {
      emptyPercantage: 'Not included in a zone evaluations',
      lowPercantage: 'Critical',
      mediumPercantage: 'Normal',
      highPercantage: 'Excellent'
    },
    clientInteractionsTypes: {
      phoneCall: 'Call',
      email: 'E-mail',
      chat: 'Chat',
      ticket: 'Ticket',
      video: 'Video',
      other: 'Other'
    },
    symbolicTimeRanges: {
      all: 'All time',
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 days',
      thisWeek: 'This week',
      previousWeek: 'Previous week',
      lastThirtyDays: 'Last 30 days',
      thisMonth: 'This month',
      previousMonth: 'Previous month',
      thisYear: 'This year',
      previousYear: 'Last year'
    },
    symbolicMode: {
      clientInteraction: ', by date of communication',
      review: ', by date of evaluation'
    },
    notificationsScheduleTypes: {
      immediately: 'Immediately',
      endOfDay: 'At the end of the day',
      endOfWeek: 'At the end of the week',
      dayAbout: 'Day about'
    },
    notificationsTypes: {
      reviewCreated: 'Evaluation created',
      replyCommentCreated: 'Comment reply',
      taskCreated: 'Automatic tag added'
    },
    syncDataPeriods: {
      threeDays: '3 days',
      week: 'A week',
      twoWeeks: '2 weeks',
      month: 'A month',
      threeMonths: '3 months',
      sixMonths: '6 months',
      allTime: 'All time'
    },
    colorZonesText: {
      low:
        'The operator has made rude mistakes in this valuation parameter that will lead to reputation/financial losses of the company or the interest of the client can no longer be repaid',
      medium:
        'The operator has made minor mistakes in this evaluation point, which will not greatly affect the company reputation or the desire of the customer to buy the product; on this parameter, the evaluation is worth tightening the skills of the operator, as it could be better here and it was described in the standards.',
      high:
        'The operator anticipated the expectations of the client, behaved in such a way that the client did not even have the opportunity to express doubts; a worthy case for handling objections / doubts / negative.'
    },
    scaleTypes: {
      max_2: {
        text: {
          yes: 'Yes',
          no: 'No'
        },
        name: 'Two score scale (0/1, yes/no)',
        valuesOptions: {
          emojis: '👍 и 👎 (👎 - 0, 👍 - 1)',
          text: 'Yes and No (No - 0, Yes - 1)',
          numbers: '0 and 1'
        }
      },
      max_3: {
        name: 'Three score scale(1, 2, 3)',
        valuesOptions: { text: '1, 2 and 3' }
      },
      max_4: { name: 'Four score scale (1, 2, 3, 4)' },
      max_5: { name: 'Five score scale (1, 2, 3, 4, 5)' },
      max_10: {
        name: 'Ten score scale (from 1 to 10)',
        valuesOptions: { numbers: 'From 1 to 10' }
      },
      custom: { name: 'Arbitrary scale' },
      custom_with_views: { name: 'Arbitrary scale (text value)' },
      custom_binary: {
        name: '2-score scale with arbitrary numbers',
        text: {
          yes: 'Yes',
          no: 'No'
        },
        valuesOptions: {
          emojis: '👍 and 👎',
          text: 'Yes and No',
          numbers: 'Arbitrary numbers'
        }
      }
    },
    checklistDefinitionStatuses: {
      draft: 'Draft',
      archived: 'Archived',
      published: 'Published'
    },
    intercomMessengerStatuses: {
      show: 'Show',
      hide: 'Hide'
    },
    widgetViewModes: {
      operators: 'Employees',
      reviewers: 'Employees',
      units: 'Units',
      questions: 'Questions',
      groups: 'Questions groups',
      table: 'Table',
      chart: 'Chart',
      score: 'Points',
      percantage: 'Percentage'
    },
    datesViewFormat: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year'
    },
    errors: {
      unknownError: 'Something went wrong, but we are working on it. Try to reload the page',
      loadingRecordError: {
        title: 'Failed to access record',
        content: 'Please contact telephony support or system administrator with this record link',
        tooltip: {
          copy: 'Copy',
          copied: 'Copied!'
        }
      }
    },
    defaultPageTitle:
      'Cloud-based QA Automation Service for Contact Centers and Sales Departments | dqolio.ru',
    hiddenNamePlaceholder: 'Unknown user',
    workPlanTaskConfigurationsStatuses: {
      draft: 'Draft',
      pending: 'Pending',
      inProgress: 'In progress',
      completed: 'Finished',
      stopped: 'Stopped'
    },
    periodRepeat: {
      day: 'Every day',
      week: 'Weekly',
      month: 'Monthly',
      never: 'No Repeat'
    },
    timeUnit: {
      day: 'days',
      week: 'weeks',
      month: 'months'
    },
    appealsStatuses: {
      pending: 'Not processed',
      inProgress: 'In progress',
      needsInformation: 'Information required',
      accepted: 'Accepted',
      rejected: 'Rejected',
      withoutAnswer: 'No answer'
    },
    appealPersonFilterValues: {
      assignedTo: 'Assigned to',
      watcher: 'Watcher',
      appellant: 'Submit an appeal'
    }
  }
};
