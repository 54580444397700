export default {
  integrationsSettingsPage: {
    proceed: 'Продолжить',
    save: 'Сохранить',
    delete: 'Удалить',
    sync: 'Синхронизировать',
    selectSyncPeriod: 'Выберите период синхронизации',
    checkboxIsUpdateOldData: 'Обновить старые данные',
    syncInternal: 'Включать внутренние звонки в синхронизацию',
    deleteConfirm: {
      title: 'Удалить интеграцию?',
      ok: 'Да',
      cancel: 'Нет'
    },
    nameAndType: {
      name: 'Название',
      integrationName: 'Введите название интеграции',
      type: 'Тип',
      integrationType: 'Выберите тип интеграции',
      clientSecretPlaceholder: 'Введите Client Secret',
      enterPlaceholderTemplate: 'Введите',
      yourB24address: 'Ваш адрес Bitrix 24',
      mangoApiKey: 'Уникальный код вашей АТС:',
      mangoSalt: 'Ключ для создания подписи:',
      clientProductCodePlaceholder: 'Введите уникальный код вашей АТС',
      clientProductCode: 'Уникальный код вашей АТС:',
      clientSalt: 'Ключ для создания подписи:',
      clientSaltPlaceholder: 'Введите ключ для создания подписи:',
      errors: {
        emptyURL: 'Пожалуйста, введите Endpoint',
        invalidURL: 'Вы ввели недопустимый Endpoint'
      }
    },
    integrationPage: {
      title: 'Интеграция',
      sync: 'Синхронизация',
      manualSync: 'Ручная синхронизация',
      fields: 'Поля',
      users: 'Пользователи',
      integrationSettings: 'Настройки интеграции',
      manualLinkText: 'Инструкция по интеграции',
      manualArticles: 'Инструкции по настройке интеграций',
      customFieldsSettings: {
        title: 'Настройки пользовательских полей',
        addField: 'Добавить поле',
        deleteConfirm: {
          title: 'Удалить пользовательское поле',
          okText: 'Удалить'
        },
        editor: {
          fieldName: 'Название поля',
          fieldKey: 'Ключ поля',
          fieldType: 'Тип поля',
          usedForFilters: 'Используется для фильтрации',
          usedForReviews: 'Используется для оценок',
          usedForAI: 'Использовать для ИИ',
          visibleForAI: 'Отображать в коммуникации',
          save: 'Сохранить',
          edit: 'Редактировать',
          delete: 'Удалить'
        }
      },
      testRequest: {
        title: 'Тестовый запрос',
        success: 'В систему добавлена коммуникация, доступная по этой ссылке',
        failure: 'Не удалось добавить тестовую коммуникацию',
        makeRequest: 'Сделать запрос'
      },
      limitSelectedFields: 'Лимит количества дополнительных полей',
      integrationFieldsList: {
        empty: 'Название поля не установлено',
        name: 'Название',
        type: 'Тип'
      },
      updateConnection: 'Обновить подключение',
      switchOn: 'Включить cинхронизацию',
      switchOff: 'Выключить cинхронизацию',
      amocrmLink: 'Ссылка на аккаунт',
      amocrmLastSynced: 'Последняя синхронизация',
      amocrmIntegrationCreated: 'Дата создания интеграции',
      amocrmConnectionStatus: 'Соединение с аккаунтом amoCRM',
      naumenConnectionStatus: 'Соединение с аккаунтом Naumen',
      ednaConnectionStatus: 'Соединение с аккаунтом Edna',
      mangoConnectionStatus: 'Соединение с аккаунтом Mango',
      amocrmActive: 'Активен',
      amocrmInactive: 'Неактивен'
    },
    messages: {
      integrationSuccessfullyCreate: 'Интеграция успешно создана',
      integrationSuccessfullyUpdated: 'Интеграция успешно обновлена',
      integrationCreateFailed: 'Не удалось создать интеграцию'
    },
    addIntegration: {
      title: 'Добавить интеграцию',
      yourIntegrations: 'Ваши интеграции'
    },
    manageUserAccounts: {
      totalUsers: 'Пользователей',
      activeUsers: 'Активных',
      selectAll: 'Выбрать все',
      columns: {
        user: 'Сотрудник',
        email: 'Email',
        id: 'ID',
        sync: 'Синхронизация',
        status: 'Cтатус во внешней системе'
      },
      add: 'Добавить',
      downloadCSV: 'Скачать пример CSV',
      manual: 'Вручную',
      fromCSV: 'Через CSV',
      sendingFile: 'Отправка файла...',
      csvModal: {
        title: 'Поздравляем',
        content: 'Вы загрузили всех пользователей из вашего CSV файла!',
        okText: 'Продолжить'
      },
      usersAlreadyInSystem: 'В qolio уже существуют пользователи с таким email',
      accountToUsers: 'Данные учётные записи будут добавлены к пользователям',
      brokenEmail: 'У пользователя отсуствует email'
    },
    createCustomIntegration: {
      addUsersTitle: 'Добавить пользователей'
    },
    createSaasIntegration: {
      errorMessage: 'Возникла ошибка при синхронизации данных с',
      errorMessageContent:
        'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io',
      sync: 'Синхронизация'
    },
    customUserAccountForm: {
      enterEmail: 'Введите email',
      enterCorrectEmail: 'Введите корректный email',
      sync: 'Синхронизируется',
      notSync: 'Не синхронизируется',
      enterId: 'Введите id'
    },
    sipuniAccountsImported:
      'Аккаунты пользователей импортированы. Для настройки их необходимо привязать к учётным записям в Qolio. Для этого перейдите в ',
    mangoAccountsImported:
      'Аккаунты пользователей импортированы. Для настройки их необходимо привязать к учётным записям в Qolio. Для этого перейдите в ',
    urlAndToken: {
      generate: 'Сгенерировать'
    },
    infoAlert: {
      message: 'Настройки синхронизации вручную недоступны.',
      description: 'Для вашей организации синхронизация производится ежедневно в {{time}}.',
      syncAlreadyInProgress:
        'Синхронизация в данный момент уже выполняется. Дождитесь завершения или попробуйте обновить страницу.',
      setTheRange:
        'Пожалуйста задайте диапазон для синхронизации данных. Обратите внимание, чем больше заданный диапазон, тем больше времени займет синхронизация. Вы всегда сможете увеличить диапазон синхронизируемых данных в настройках интеграции.'
    },
    unsupportedField: 'Не поддерживаемое поле',
    changeChanel: {
      title: 'Переключение каналов',
      description:
        'Данная опция позволяет менять местами каналы менеджера и клиента в тексте транкрибации коммуникации'
    }
  }
};
