import { DownOutlined, RightOutlined } from '@ant-design/icons';
import Icon from 'components/Icon';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { CategoryName, CategoryNameBlock } from './styled';
import FunnelsFilters from './FunnelsFilters';

const AdditionalInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { collapsedFilterGroups } = useSelector(state => state.uiClientInteractions);
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('customFieldsFilters');

  return (
    <SRow gutter={[0, 16]} style={{ marginTop: '8px', marginBottom: '-8px' }}>
      <SCol span={24}>
        <Row>
          <Col span={24}>
            <CategoryNameBlock
              type="flex"
              align="middle"
              justify="space-between"
              onClick={() => dispatch(toogleFilterGroup('customFieldsFilters'))}
            >
              <Col span={22}>
                <CategoryName>
                  {t('clientInteractionsPage.tableFilters.customFieldFilters.title')}
                </CategoryName>
              </Col>
              <Col span={2}>
                {isCollapsedFilterGroup ? (
                  <Icon icon={DownOutlined} />
                ) : (
                  <Icon icon={RightOutlined} />
                )}
              </Col>
            </CategoryNameBlock>
          </Col>

          <FunnelsFilters />
        </Row>
      </SCol>
    </SRow>
  );
};

export default AdditionalInformation;
