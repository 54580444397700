export default {
  templates: {
    templates: 'Modelli',
    addTemplate: 'Aggiungi modello',
    loadingTemplates: 'Caricamento modelli... 😤',
    empty: 'Nessun modello',
    confirmDelete: 'Elimina modello?',
    searchTemplate: 'Cerca modello'
  }
};
