import React from 'react';
import { Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { stringify } from 'core/utils/queryString';
import { getFilters } from '../../utils';
import GetUserPreview from '../../../../components/UserPreview/GetUserPreview';

const { Text } = Typography;

export const prepareColumns = ({ checklistItems, filters }) => {
  const itemsColumns = checklistItems.reduce((acc, item) => {
    const hasScore = !isEmpty(item.scores);
    const data = {
      title: (
        <Tooltip title={item.name} placement="topLeft">
          <Text>{item.name}</Text>
        </Tooltip>
      ),
      dataIndex: item.id,
      key: item.id,
      align: 'center',
      ellipsis: true,
      sorter: !hasScore,
      children: hasScore
        ? Object.values(item.scores).map(child => ({
            title: (
              <Tooltip title={child.name} placement="topLeft">
                <Text>{child.name}</Text>
              </Tooltip>
            ),
            dataIndex: child.id,
            key: child.id,
            width: 100,
            align: 'center',
            sorter: true
          }))
        : undefined,
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: <Text>{text}</Text>
        };
      }
    };
    return [...acc, data];
  }, []);

  return [
    {
      title: 'Оператор',
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 250,
      fixed: 'center',
      ellipsis: true,
      sorter: false,
      render: (text, record) => {
        if (record?.key === 'footer') {
          return 'Всего';
        }
        return (
          <GetUserPreview
            userId={text}
            altName={record?.name}
            link={`/user/${text}/charts${stringify({
              filters: {
                ...getFilters(filters)
              }
            })}`}
          />
        );
      }
    },
    ...itemsColumns,
    {
      title: 'Итого',
      dataIndex: 'totalScore',
      key: 'totalScore',
      width: 100,
      fixed: 'right',
      align: 'center',
      ellipsis: true,
      sorter: true,
      render: text => {
        return <Text>{text}</Text>;
      }
    }
  ];
};

export const prepareRows = ({ widgetValue }) => {
  const getScore = item => {
    const value = parseInt(item?.origin_score ?? 0, 10);
    return value === 0 ? null : Math.floor(value);
  };

  const calculateTotals = () => {
    const totals = {};

    widgetValue.forEach(item => {
      if (!item || !item.scores) return;

      Object.entries(item.scores).forEach(([id, score]) => {
        totals[id] = (totals[id] || 0) + getScore(score);
      });
    });

    return totals;
  };

  const getTotalScore = item => {
    const scores = Object.values(item.scores || {}).map(score => getScore(score));
    return scores.reduce((total, score) => total + score, 0);
  };

  const rows = widgetValue.reduce((acc, item) => {
    if (!item) return acc;

    const scores = item.scores || {};

    const childrenData = Object.fromEntries(
      Object.entries(scores).map(([id, score]) => [id, getScore(score)])
    );

    const data = {
      key: item.review_id,
      operatorId: item.operator_id,
      totalScore: getTotalScore(item),
      ...childrenData
    };

    acc.push(data);
    return acc;
  }, []);

  const totalFieldsSum = calculateTotals();
  const totalScore = Object.values(totalFieldsSum).reduce(
    (acc, score) => acc + parseInt(score, 10),
    0
  );

  rows.push({
    key: 'footer',
    name: 'Всего',
    sorter: false,
    totalScore,
    ...totalFieldsSum
  });

  return rows;
};

export const getChecklistItems = (customFields, widgetValue) => {
  return Object.values(customFields)
    .map(field => {
      const containsId = Object.values(widgetValue).some(operator =>
        Object.values(operator?.scores).some(answer => answer?.parent_id === field?.id)
      );
      if (!containsId) {
        return null;
      }

      const scores = {};
      for (const operator of Object.values(widgetValue)) {
        for (const [answerId, scoreData] of Object.entries(operator?.scores)) {
          if (scoreData?.parent_id === field.id) {
            if (!scores[answerId]) {
              scores[answerId] = {
                id: answerId,
                name: scoreData?.name
              };
            }
          }
        }
      }

      let isOneAnswer = false;
      let answerId = '';

      Object.values(widgetValue).map(item => {
        const scores = item?.scores;

        const scoreEntry = Object.entries(scores).find(
          ([key, score]) => score?.parent_id === field?.id
        );
        if (scoreEntry) {
          const [key, score] = scoreEntry;
          if (score?.oneAnswer) {
            isOneAnswer = true;
            answerId = key;
          }
        }
      });

      return {
        id: isOneAnswer ? answerId : field?.id,
        // name: isOneAnswer ? Object.values(scores)[0]?.name : field?.name,
        // scores: Object.keys(scores).length > 0 && !isOneAnswer ? scores : {}
        name: field?.name,
        scores
      };
    })
    .filter(item => item !== null);
};
