import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Select } from 'antd';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { updateUiWidgetFilters } from 'redux/ui/widgetSettingsModal/reducer';

const { Option } = Select;

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

const WidgetReviewStatus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.uiWidgetSettingsModal);

  return (
    <Col span={24}>
      <Text strong>
        Статус проверки
      </Text>
      <FilterContainer>
        <StyledSelect
          placeholder={t(
            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
          )}
          mode="default"
          style={{ width: '100%' }}
          onChange={reviewStatus => dispatch(updateUiWidgetFilters({ reviewStatus }))}
          value={filters.reviewStatus || 'all'}
          optionLabelProp="label"
        >
          <Option
            key="all"
            value="all"
            label={`${t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
            )}: ${t('constants.reviewStatuses.all')}`}
          >
            {t('constants.reviewStatuses.all')}
          </Option>
          <Option
            key="reviewed"
            value="true"
            label={`${t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
            )}: ${t('constants.reviewStatuses.reviewed')}`}
          >
            {t('constants.reviewStatuses.reviewed')}
          </Option>
          <Option
            key="notReviewed"
            value="false"
            label={`${t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
            )}: ${t('constants.reviewStatuses.notReviewed')}`}
          >
            {t('constants.reviewStatuses.notReviewed')}
          </Option>
        </StyledSelect>
      </FilterContainer>
    </Col>
  );
};

export default WidgetReviewStatus;
