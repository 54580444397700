export default {
  templates: {
    templates: 'Templates',
    addTemplate: 'Add Template',
    loadingTemplates: 'Loading templates',
    empty: 'No templates',
    confirmDelete: 'Delete template?',
    searchTemplate: 'Search template'
  }
};
